.block-humans {
    display: grid;
    gap: 4rem 1rem;
    max-width: var(--width-main);
    margin: 0 auto;
    /*padding: var(--block-space-vertical) var(--block-space-horizontal);*/

    padding-left: var(--block-space-horizontal);
    padding-right: var(--block-space-horizontal);
}

.block-human {
    align-content: start;
    display: grid;
    gap: 1rem;
}

.block-human h2 {
    /*margin-bottom: 2rem;*/
}

.block-human h3, .block-human h4, .block-human h5{
    /*margin-bottom: 1rem;*/
}

.block-human h4 {
    color: var(--color-yellow);
}


.block-human a {
    text-decoration: none;
    font-weight: bold;
}

.block-human a:hover {
    text-decoration: underline;
    font-weight: bold;
}

@media (min-width: 600px) {
    .block-humans {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 800px) {
    .block-human {
        grid-template-columns: 33.33% auto;
    }
}

